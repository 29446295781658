import { FC, createContext, useContext } from 'react'
import './StakingCard.scss'
import { RhombusButton } from '../../../../ui/buttons/rhombus/RhombusButton'
import { StakingCardTitle } from '../../title/StakingCardTitle'
import { StakingCardContent } from './content/staking/StakingCardContent'
import { ConnectWalletCardContent } from './content/connect-wallet/ConnectWalletCardContent'
import { LockedInfo } from '../locked-info/LockedInfo'
import { Border } from '../../../../ui/borders/Border'
import { PopupContext } from '../../../../contexts/PopupContext'
import { PercentRate } from '../../../percent-rate/PercentRate'
import {
  ConnectWalletContentLoading,
  InstallMetamsk
} from '../../../popup/content/PopupContent'
import { useAccount } from 'wagmi'
import { useWeb3Modal } from '@web3modal/react'

interface IStakingCard {
  imgSrc: string
  title: string
  isLocked?: boolean
  role: string
  className: string
  action: () => void
  visible?: boolean
}

interface IStakingCardContext {
  isLocked: boolean
}

export const StakingCardContext = createContext<IStakingCardContext>(
  null as any
)

export const StakingCard: FC<IStakingCard> = ({
  title,
  imgSrc,
  isLocked = false,
  className,
  action,
  visible
}) => {
  const { specifyComponent, close, open: openModal } = useContext(PopupContext)
  const { isConnected } = useAccount()
  const { open } = useWeb3Modal()

  const handleConnectClick = () => {
    if (!isConnected) {
      open()
      specifyComponent(<ConnectWalletContentLoading />)
      openModal()
      close()
    } else {
      specifyComponent(<InstallMetamsk />)
      openModal()
    }
  }

  return (
    <StakingCardContext.Provider value={{ isLocked }}>
      <Border
        className={className}
        classNameInner="card-inner"
        onClick={action}
      >
        <div className="card__inner">
          <PercentRate />
          {isLocked && <LockedInfo />}
          <StakingCardTitle title={title} />
          {isConnected ? <StakingCardContent /> : <ConnectWalletCardContent />}
          {!isConnected && (
            <RhombusButton onClick={handleConnectClick} text="Connect Wallet" />
          )}
        </div>
        <div className="card__image">
          <img src={imgSrc} alt="card" className="card__image_img" />
        </div>
      </Border>
    </StakingCardContext.Provider>
  )
}
