import React, { FC } from 'react'
import './StakingWarning.scss'
import WarningImg from '../../../../../assets/icons/WarningWhite.svg'

interface IStakingWarning {
  text: string
  isRed?: boolean
}

export const StakingWarning: FC<IStakingWarning> = ({ text, isRed }) => {
  return (
    <div className="staking-warning">
      <div className="staking-warning__inner">
        <img src={WarningImg} alt="warning" className="staking-warning__img" />
        <span className="staking-warning__text">{text}</span>
      </div>
    </div>
  )
}
