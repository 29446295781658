import React, { FC } from 'react'
import './PopupElements.scss'

export const Title = (props: any) => {
  return <div className="popup-title">{props.text}</div>
}

export const Percent = (props: any) => {
  return (
    <div className={`staking-content-buttons-percent ${props.className}`}>
      <div
        onClick={props.action}
        className={`staking-content-buttons-percent__item ${props.className}`}
      >
        {props.text}%
      </div>
    </div>
  )
}
interface ITextArea {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  isLocked?: boolean
}

export const TextArea: FC<ITextArea> = ({ onChange, value, isLocked }) => {
  return (
    <div className="staking-textarea">
      <div className="staking-textarea__inner">
        <input
          className="staking-textarea__input"
          onChange={onChange}
          placeholder="0"
          value={value}
        />
        {/* <div className="staking-textarea__fee">~0,02 USD</div> */}
      </div>
    </div>
  )
}

export const Info = (props: any) => {
  return (
    <div className="popup-info">
      <div className="popup-info__text">{props.text}</div>
    </div>
  )
}
