import { Watch } from 'react-loader-spinner'
import './Loader.scss'
import { useContext } from 'react'
import { PopupContext } from '../../contexts/PopupContext'

export const Loader = () => {
  const { loaderActive } = useContext(PopupContext)
  return (
    <div className={loaderActive ? 'loader loader_active' : 'loader'}>
      <Watch
        height="80"
        width="80"
        radius="48"
        color="#fff"
        ariaLabel="watch-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  )
}
