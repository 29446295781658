import React from 'react'
import LogoImg from '../../../assets/logo.png'
import './Logo.scss'
import { Link } from 'react-router-dom'

export const Logo = () => {
  return (
    <Link className="logo" to="/">
      <img className="logo__img" src={LogoImg} alt="logo" />
    </Link>
  )
}
