import React, { PropsWithChildren, useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { providers } from 'ethers'
import { PopupState } from './components/contexts/PopupContext'
import { Web3Provider } from './components/contexts/Web3Context'
import { BrowserRouter } from 'react-router-dom'
import { Web3Modal } from '@web3modal/react'
import {
  PublicClient,
  WagmiConfig,
  configureChains,
  createConfig,
  usePublicClient,
  useWalletClient,
  type WalletClient
} from 'wagmi'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { polygon } from 'wagmi/chains'
import { HttpTransport } from 'viem'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

export const projectId = '1ea8a05002473a5774a4d8c62c556960'

// Wagmi client
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygon],
  [w3mProvider({ projectId: projectId })]
)

const wagmiClient = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
  webSocketPublicClient
})

const ethereumClient = new EthereumClient(wagmiClient, chains)

export function publicClientToProvider(publicClient: PublicClient) {
  const { chain, transport } = publicClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address
  }
  if (transport.type === 'fallback')
    return new providers.FallbackProvider(
      (transport.transports as ReturnType<HttpTransport>[]).map(
        ({ value }) => new providers.JsonRpcProvider(value?.url, network)
      )
    )
  return new providers.JsonRpcProvider(transport.url, network)
}

/** Hook to convert a viem Public Client to an ethers.js Provider. */
export function useEthersProvider({ chainId }: { chainId?: number } = {}) {
  const publicClient = usePublicClient({ chainId })
  return React.useMemo(
    () => publicClientToProvider(publicClient),
    [publicClient]
  )
}

export function walletClientToSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address
  }
  const provider = new providers.Web3Provider(transport, network)
  return provider.getSigner(account.address)
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner() {
  const polygonId = Number.parseInt(polygon.id.toString())

  const { data: walletClient } = useWalletClient({ chainId: polygonId })
  return React.useMemo(
    () => (walletClient ? walletClientToSigner(walletClient) : undefined),
    [walletClient]
  )
}

const AppWrapper = ({ children }: PropsWithChildren) => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setReady(true)
  }, [])

  return (
    <>
      {ready && (
        <WagmiConfig config={wagmiClient}>
          <BrowserRouter>
            <Web3Provider>
              <PopupState>{children}</PopupState>
            </Web3Provider>
          </BrowserRouter>
        </WagmiConfig>
      )}
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeMode="dark"
        defaultChain={polygon}
      ></Web3Modal>
    </>
  )
}

root.render(
  <AppWrapper>
    <App />
  </AppWrapper>
)
