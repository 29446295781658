import { useContext, useEffect, useState } from 'react'
import { Header } from './components/elements/header/Header'
import { Staking } from './components/elements/staking/Staking'
import { Popup } from './components/elements/popup/Popup'
import { PopupContext } from './components/contexts/PopupContext'
import { NetworkNotSupported } from './components/elements/not-supported/NetworkNotSupported'
import { useAccount, useNetwork } from 'wagmi'
import './StakingPage.scss'
import { Loader } from './components/elements/loader/Loader'

export const StakingPage = () => {
  const { notification } = useContext(PopupContext)
  const [supported, setSupported] = useState(false)
  const { chain } = useNetwork()
  const { isConnected } = useAccount()

  useEffect(() => {
    if (chain) {
      setSupported(!chain.unsupported)
    }
  }, [chain])

  return (
    <div className="wrapper staking-page">
      {notification}
      <Loader />
      <Popup />
      <Header />

      {supported || !isConnected ? <Staking /> : <NetworkNotSupported />}
    </div>
  )
}
