import { useContext } from 'react'
import './Statistic.scss'
import { Web3Context } from '../../../contexts/Web3Context'
import { Watch } from 'react-loader-spinner'

export const Statistic = () => {
  return (
    <section className="welcome">
      <div className="wrapper">
        <StatisticItems />
      </div>
    </section>
  )
}

export const StatisticItems = () => {
  const { lockedApr, unlockApr, totalStaked } = useContext(Web3Context)
  const size = '30'
  const Loader = (
    <Watch
      height={size}
      width={size}
      radius="48"
      color="#fff"
      ariaLabel="watch-loading"
      wrapperStyle={{}}
      visible={true}
    />
  )
  return (
    <div className="welcome__items">
      <div className="row">
        <div className="welcome__item1">
          <h2 className="welcome__item-title">Estimated rewards</h2>
          <div className="welcome__block">
            <div className="welcome__block-content">
              <div className="welcome__item">
                <div className="welcome__subtitle">APR (with lock)</div>
                <div className="welcome__num">
                  {lockedApr === 0 ? Loader : lockedApr}%
                </div>
              </div>
              <div className="welcome__item">
                <div className="welcome__subtitle">APR (no lock)</div>
                <div className="welcome__num">
                  {unlockApr === 0 ? Loader : unlockApr}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="welcome__item2">
          <h2 className="welcome__item-title">RIA staking statistics</h2>
          <div className="welcome__block">
            <div className="welcome__block-content">
              <div className="welcome__item">
                <div className="welcome__subtitle">Daily rewards</div>
                <div className="welcome__num">
                  {lockedApr === 0 && unlockApr === 0
                    ? Loader
                    : ((lockedApr + unlockApr) / 365 / 2).toFixed(4)}
                  %
                </div>
              </div>
              <div className="welcome__item">
                <div className="welcome__subtitle">Total staked</div>
                <div className="welcome__num">
                  {totalStaked === '' ? Loader : `${totalStaked} RIA`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
