import React, { FC } from 'react'
import './SubTable.scss'

interface ISubTable {
  children: JSX.Element | JSX.Element[]
  titleDate: string
  titleTokenAmount: string
  titlePercentage: string
}

export const SubTable: FC<ISubTable> = ({
  children,
  titleDate,
  titleTokenAmount,
  titlePercentage
}) => {
  return (
    <div className="subtable">
      <div className="subtable__inner">
        <div className="subtable__head">
          <div className="subtable__item">{titleDate}</div>
          <div className="subtable__item">{titleTokenAmount}</div>
          <div className="subtable__item">{titlePercentage}</div>
        </div>
        <div className="subtable__content">{children}</div>
      </div>
    </div>
  )
}
