import { useContext, useEffect, useState } from 'react'
import { Table } from '../../table/Table'
import { Row } from '../../table/row/Row'
import { RowItem } from '../../table/row/item/RowItem'
import { RowContent } from '../../table/row/content/RowContent'
import { ActionButton } from '../../../../../ui/buttons/action/ActionButton'
import { RowButtons } from '../../table/row/buttons/RowButtons'
import { SubTable } from '../../table/subtable/SubTable'
import { SubRow } from '../../table/subtable/subrow/SubRow'
import { Amount, Date as DateElement } from '../../table/elements/TableElements'
import { ThereIsNoStakes } from '../../Tabs'
import { Web3Context } from '../../../../../contexts/Web3Context'
import {
  bigNumberToDate,
  daysToMilliseconds,
  getDaysDiff,
  toBalance,
  toFormattedDate
} from '../../../../../utils/Utils'
import { PopupContext } from '../../../../../contexts/PopupContext'
import {
  Harvest,
  Reinvest,
  UnstakeContent
} from '../../../../popup/content/PopupContent'
import { StakingContext } from '../../../Staking'
import { StakingWarning } from '../../../cards/warning/StakingWarning'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { useMediaQuery } from 'react-responsive'
import { StakePopup } from '../../table/popup/StakePopup'
import Lock from '../../../../../../assets/icons/lock.svg'

const WithStake = () => {
  const { stakeInfo, getBlockTimestamp } = useContext(Web3Context)
  const { specifyComponent, open } = useContext(PopupContext)
  const { locked } = useContext(StakingContext)
  const [activeRow, setActiveRow] = useState(-1)
  const [blockDate, setBlockDate] = useState(Date.now() / 1000)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 830px)' })
  const [popup, setPopup] = useState(null as any)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    const getTimestamp = async () => {
      const timestamp = await getBlockTimestamp()
      if (timestamp) setBlockDate(timestamp)
    }
    getTimestamp()
  }, [])

  useEffect(() => {
    setActiveRow(-1)
  }, [locked])

  return (
    <>
      {modal && popup}
      <Table titleDate="Staking Date" titleTokenAmount="Staked $RIA Tokens">
        {stakeInfo
          .filter((el) => el.data.isLocked === locked)
          .sort((a, b) => b.data.stakeStart.sub(a.data.stakeStart).toNumber())
          .map((el, index) => (
            <Row key={index} isExpanded={activeRow === index}>
              <RowItem>
                <RowContent>
                  <DateElement date={toFormattedDate(el.data.stakeStart)} />
                  <Amount number={toBalance(el.data.amount)} money="~201 USD" />
                </RowContent>
                <RowButtons>
                  {el.data.stakeEnd.isZero() || blockDate > el.data.stakeEnd ? (
                    <>
                      <ActionButton
                        action={() => {
                          specifyComponent(
                            <Reinvest
                              earned={el.earned}
                              isLocked={locked}
                              staked={el.data.amount}
                              stakeId={el.stakeId}
                            />
                          )
                          open()
                        }}
                        text="Reinvest"
                        isDisabled={el.earned.isZero()}
                      />
                      {!locked && (
                        <ActionButton
                          action={() => {
                            specifyComponent(
                              <Harvest
                                reward={el.earned}
                                isLocked={locked}
                                stakeId={el.stakeId}
                              />
                            )
                            open()
                          }}
                          color="bronze"
                          text="Harvest"
                          isDisabled={el.earned.isZero()}
                        />
                      )}
                      <ActionButton
                        action={() => {
                          specifyComponent(
                            <UnstakeContent
                              locked={locked}
                              earned={el.earned}
                              staked={el.data.amount}
                              stakeId={el.stakeId}
                            />
                          )
                          open()
                        }}
                        color="bronze"
                        text="Unstake"
                      />
                    </>
                  ) : !isTabletOrMobile ? (
                    <StakingWarning
                      text={`Lock until ${toFormattedDate(el.data.stakeEnd)}`}
                    />
                  ) : (
                    <img src={Lock} alt="" />
                  )}
                  {isTabletOrMobile ? (
                    <Payout
                      action={() => {
                        setPopup(
                          <StakePopup
                            stake={el}
                            action={() => {
                              setModal(false)
                              document.body.style.overflow = 'visible'
                            }}
                          />
                        )
                        document.body.style.overflow = 'hidden'
                        setModal(true)
                      }}
                    />
                  ) : (
                    getDaysDiff(
                      blockDate,
                      bigNumberToDate(el.data.stakeStart)
                    ) > 0 && (
                      <Payout
                        action={() =>
                          setActiveRow((prev) => (prev === index ? -1 : index))
                        }
                      />
                    )
                  )}
                </RowButtons>
              </RowItem>
              <SubTable
                titleDate="Payout Date"
                titlePercentage="Payout Percentage"
                titleTokenAmount="Earned $RIA Tokens"
              >
                {Array.from(
                  {
                    length: el.data.isLocked
                      ? getDaysDiff(
                          blockDate > el.data.stakeEnd
                            ? el.data.stakeEnd
                            : blockDate,
                          bigNumberToDate(el.data.stakeStart)
                        )
                      : getDaysDiff(
                          blockDate,
                          bigNumberToDate(el.data.stakeStart)
                        )
                  },
                  (_, i) => i + 1
                )
                  .reverse()
                  .map((num) => (
                    <SubRows key={num} el={el} locked={locked} num={num} />
                  ))}
              </SubTable>
            </Row>
          ))}
      </Table>
    </>
  )
}

const Payout = (props: any) => (
  <span onClick={props.action} className="table-row-item__payout">
    Payouts
  </span>
)

const SubRows = (props: any) => {
  const { calculateRewardRaw } = useContext(Web3Context)
  const { updateLoader } = useContext(PopupContext)
  const [count, setCount] = useState(BigNumber.from(0))

  useEffect(() => {
    const updateCount = async () => {
      updateLoader(true)
      const value = await calculateRewardRaw(
        props.el.data.amount,
        //hours for now
        daysToMilliseconds(1) / 1000,
        props.el.data.apr
      )
      if (value) setCount(value)
      updateLoader(false)
    }
    updateCount()
  }, [props.el.data.amount, props.el.data.apr, props.num])

  return (
    <SubRow>
      <RowContent>
        <DateElement
          date={new Date(
            bigNumberToDate(props.el.data.stakeStart).getTime() +
              daysToMilliseconds(props.num)
          ).toLocaleDateString('en-us', {
            month: 'short',
            day: 'numeric'
          })}
        />
        <Amount number={toBalance(count)} />
        <div className="subrow__percent">
          {count.isZero()
            ? `0%`
            : `${(
                (+formatEther(count) / +formatEther(props.el.data.amount)) *
                100
              ).toFixed(4)}%`}
        </div>
      </RowContent>
    </SubRow>
  )
}

export const StakesContent = () => {
  const { stakeInfo } = useContext(Web3Context)
  const { locked } = useContext(StakingContext)
  const hasStakes =
    stakeInfo.filter((el) => el.data.isLocked === locked).length > 0
  return hasStakes ? (
    <WithStake />
  ) : (
    <ThereIsNoStakes text="You didn't stake any tokens" />
  )
}
