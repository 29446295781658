import { useContext } from 'react'
import './Header.scss'
import { Logo } from '../../ui/logo/Logo'
import { ConnectWallet } from '../../metamask/buttons/Buttons'
import { Avatar } from '../../ui/avatar/Avatar'
import { PopupContext } from '../../contexts/PopupContext'
import { WalletContent } from '../popup/content/PopupContent'
import { useAccount } from 'wagmi'

export const Header = () => {
  const { specifyComponent, open } = useContext(PopupContext)
  const { isConnected } = useAccount()

  return (
    <div className="header">
      <div className="header__inner">
        <div className="header__top-img"></div>
        <div className="header__bottom-img"></div>
        <Logo />
        <div className="header__content">
          <div className="header__launch">Calvaria Sacrificial Altars</div>
          {isConnected ? (
            <Avatar
              action={() => {
                specifyComponent(<WalletContent />)
                open()
              }}
            />
          ) : (
            <ConnectWallet />
          )}
        </div>
      </div>
    </div>
  )
}
