import React, { FC } from 'react'
import './Table.scss'

interface ITable {
  children: JSX.Element[] | JSX.Element
  titleDate: string
  titleTokenAmount: string
}

export const Table: FC<ITable> = ({
  children,
  titleDate,
  titleTokenAmount
}) => {
  return (
    <div className="staking-table">
      <div className="staking-table__inner">
        <div className="staking-table__head">
          <div className="staking-table__item">{titleDate}</div>
          <div className="staking-table__item">{titleTokenAmount}</div>
        </div>
        <div className="staking-table__content">{children}</div>
      </div>
    </div>
  )
}
