import { useContext, useEffect, useState } from 'react'
import { StakesContent } from './content/stakes/StakesContent'
import { TabsSwitcher } from './switcher/TabsSwitcher'
import './Tabs.scss'
import { Harvests } from './content/harvests/Harvests'
import { TabsSwitcherItem } from './switcher/item/TabsSwitcherItem'
import { StakingContext } from '../Staking'

export const ThereIsNoStakes = (props: any) => {
  return <div className="without-staking-title">{props.text}</div>
}

export const StakingTabs = () => {
  const [isStake, setIsStake] = useState(true)
  const { locked } = useContext(StakingContext)
  useEffect(() => setIsStake(true), [locked])
  return (
    <div className="tabs">
      <div className="tabs__inner">
        <TabsSwitcher>
          <TabsSwitcherItem
            onClick={() => setIsStake(true)}
            isDisabled={isStake === false}
            title="Stakes & Payouts"
          />
          {!locked && (
            <TabsSwitcherItem
              onClick={() => setIsStake(false)}
              isDisabled={isStake === true}
              title="Harvests"
            />
          )}
        </TabsSwitcher>
        {isStake ? <StakesContent /> : <Harvests />}
      </div>
    </div>
  )
}
