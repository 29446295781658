import { useContext } from 'react'
import Lock from '../../../../../assets/icons/lock.svg'
import './LockedInfo.scss'
import { Web3Context } from '../../../../contexts/Web3Context'
import { Watch } from 'react-loader-spinner'

export const LockedInfo = () => {
  const { minimalLockDuration } = useContext(Web3Context)
  const size = '20'
  const Loader = (
    <Watch
      height={size}
      width={size}
      radius="48"
      color="#fff"
      ariaLabel="watch-loading"
      wrapperStyle={{}}
      visible={true}
    />
  )

  return (
    <div className="locked-info">
      <img src={Lock} alt="locked" className="locked-info__img" />
      <span className="locked-info__text">
        min lock {minimalLockDuration === 0 ? Loader : minimalLockDuration} days
        +
      </span>
    </div>
  )
}
