import React, { FC } from 'react'
import './Row.scss'

interface IRow {
  children?: JSX.Element[] | JSX.Element | boolean
  isExpanded?: boolean
}

export const Row: FC<IRow> = ({ children, isExpanded }) => {
  return (
    <div className={`table-row${isExpanded ? ' table-row_active' : ''}`}>
      <div className="table-row__inner">{children}</div>
    </div>
  )
}
