import React, { FC, useContext } from 'react'
import ErrorIcon from '../../../assets/icons/error-icon.svg'
import SuccessIcon from '../../../assets/icons/success-icon.svg'
import { PopupBorder } from '../../ui/borders/Border'
import './Notification.scss'
import { PopupContext } from '../../contexts/PopupContext'

interface INotification {
  isSuccess?: boolean
  text?: string
}

export const Notification: FC<INotification> = ({ isSuccess, text }) => {
  const { isNotification, closeNotification } = useContext(PopupContext)
  return (
    <PopupBorder
      className={isNotification ? 'notification active' : 'notification'}
      classNameInner="notification__inner"
    >
      <div className="notification__inner">
        <div onClick={closeNotification} className="notification__exit"></div>
        <div className="notification__icon">
          <img
            className="notification__icon-image"
            src={isSuccess ? SuccessIcon : ErrorIcon}
            alt="notification-icon"
          />
        </div>
        <div className="notification__content">
          <div className="notification__title">
            {isSuccess ? 'Success' : 'Error'}
          </div>
          <div className="notification__text">{text}</div>
        </div>
      </div>
    </PopupBorder>
  )
}
