import React, { FC } from 'react'
import './SubRow.scss'

interface ISubRow {
  children?: JSX.Element[] | JSX.Element
}

export const SubRow: FC<ISubRow> = ({ children }) => {
  return (
    <div className="subrow">
      <div className="subrow__inner">{children}</div>
    </div>
  )
}
