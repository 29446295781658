import { useContext } from 'react'
import { PopupBorder } from '../../ui/borders/Border'
import './Popup.scss'
import { PopupContext } from '../../contexts/PopupContext'

export const Popup = () => {
  const { close, component, modal } = useContext(PopupContext)
  return (
    <div className={modal ? 'popup popup_active' : 'popup'}>
      <div className="popup__inner">
        <div onClick={close} className="popup__exit"></div>
        <PopupBorder>{component}</PopupBorder>
      </div>
    </div>
  )
}
