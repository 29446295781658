import React, { FC } from 'react'
import './TabsSwitcherItem.scss'

interface ITabsSwitcherItem {
  isDisabled?: boolean
  title: string
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined
}

export const TabsSwitcherItem: FC<ITabsSwitcherItem> = ({
  isDisabled = false,
  title,
  onClick
}) => {
  return (
    <div
      className={`tabs-switcher-item${
        isDisabled ? ' tabs-switcher-item_disabled' : ''
      }`}
      onClick={onClick}
    >
      {title}
    </div>
  )
}
