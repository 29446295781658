import React, { FC } from 'react'
import './CommonButton.scss'

interface ICommonButton {
  text: string
  isDisabled?: boolean
  onClick: () => void
}

export const CommonButton: FC<ICommonButton> = ({
  text,
  isDisabled,
  onClick
}) => {
  return (
    <button
      onClick={onClick}
      className={`common-button${isDisabled ? ' common-button_disabled' : ''}`}
    >
      <div className="common-button__inner">
        <span className="common-button__text">{text}</span>
      </div>
    </button>
  )
}
