import React, { FC } from 'react'
import './StakingCardTitle.scss'

interface IStakingCardTitle {
  title: string
}

export const StakingCardTitle: FC<IStakingCardTitle> = ({ title }) => {
  return <h3 className="staking-title">{title}</h3>
}
