import { BigNumber, ethers } from 'ethers'

export const copy = (text: string) => navigator.clipboard.writeText(text)

export const bigNumberToDate = (number: BigNumber) =>
  new Date(parseInt(number.mul(1000).toString()))

export const toFormattedDate = (number: BigNumber) =>
  new Date(parseInt(number.mul(1000).toString())).toLocaleDateString('en-us', {
    month: 'short',
    day: 'numeric'
  })

export const toBalance = (balance: BigNumber) =>
  ethers.utils.formatEther(balance.sub(balance.mod(1e14)))

export const toMulBalance = (balance: BigNumber) =>
  balance.mul(BigNumber.from(10).pow(18))

export const secondsToDays = (second: BigNumber) =>
  Math.floor(second.toNumber() / 60 / 60 / 24)

export const getDaysDiff = (timestamp: number, date: Date) =>
  // Math.floor(
  //   Math.abs(new Date(timestamp * 1000).getTime() - date.getTime()) / 36e5
  // )
  Math.floor(
    Math.abs(new Date(timestamp * 1000).getTime() - date.getTime()) /
      (1000 * 3600 * 24)
  )

export const daysToMilliseconds = (days: number) =>
  //days * 60 * 60 * 1000
  days * 24 * 60 * 60 * 1000

export const reductedAddress = (address: string) =>
  address.length > 4
    ? `${address.substring(0, 4)}...${address.substring(
        address.length - 4,
        address.length
      )}`
    : address

export const replaceToDigits = (value: string) =>
  value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')

export {}
