import { FC, useEffect, useState } from 'react'
import './Avatar.scss'
import avatarImg from '../../../assets/wallet/avatar.svg'
import { useAccount } from 'wagmi'
import { reductedAddress } from '../../utils/Utils'

interface IAvatar {
  action?: () => void
}

export const Avatar: FC<IAvatar> = ({ action }) => {
  const { address: currentAddress } = useAccount()
  const [address, setAddress] = useState('...')

  useEffect(() => {
    if (currentAddress) setAddress(reductedAddress(currentAddress))
  }, [address, currentAddress])

  return (
    <div onClick={action} className="avatar">
      <img src={avatarImg} alt="avatar" className="avatar__image" />
      <div className="avatar__address">{address}</div>
    </div>
  )
}
