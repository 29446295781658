import React, { FC, useContext, useEffect, useState } from 'react'
import BlackSquare from '../../../../assets/icons/black-square.svg'
import SumbittedIcon from '../../../../assets/icons/submitted.svg'
import DeclinedIcon from '../../../../assets/icons/declined.svg'
import { ActionButton } from '../../../ui/buttons/action/ActionButton'
import './PopupContent.scss'
import { Info, Percent, TextArea, Title } from '../elements/PopupElements'
import { StakingWarning } from '../../staking/cards/warning/StakingWarning'
import { Avatar } from '../../../ui/avatar/Avatar'
import { Web3Context } from '../../../contexts/Web3Context'
import { copy, replaceToDigits, toBalance } from '../../../utils/Utils'
import { RhombusButton } from '../../../ui/buttons/rhombus/RhombusButton'
import { PopupContext } from '../../../contexts/PopupContext'
import { BigNumber } from 'ethers'
import { parseEther } from 'ethers/lib/utils'
import { useAccount, useDisconnect } from 'wagmi'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { CommonButton } from '../../../ui/buttons/common/CommonButton'

export const WalletContent = () => {
  const { close, updateNotification, openNotification } =
    useContext(PopupContext)
  const { disconnect } = useDisconnect()
  const { address } = useAccount()
  const navigate = useNavigate()
  const handleDisconnect = () => {
    disconnect()
    close()
    navigate('/')
  }
  return (
    <div className="wallet-content">
      <div className="wallet-content__inner">
        <Title text="Wallet" />
        <Avatar />
        <div className="wallet-content__buttons">
          <ActionButton
            action={() => {
              copy(address ? address : '')
              updateNotification(true, 'Address successfully copied')
              openNotification()
            }}
            text="Copy Address"
          />
          <ActionButton action={handleDisconnect} text="Disconnect" />
        </div>
      </div>
    </div>
  )
}

export const ConnectWalletContentLoading = () => {
  return (
    <div className="connect-wallet-content">
      <div className="connect-wallet-content__inner">
        <div className="connect-wallet-content__square">
          <img src={BlackSquare} alt="square" />
        </div>
        <div className="connect-wallet-content__title">
          Waiting for confirmation
        </div>
      </div>
    </div>
  )
}
//NOT USED
export const ConnectWalletContentProgress = (props: any) => {
  return (
    <div className="connect-wallet-content">
      <div className="connect-wallet-content__inner">
        <div className="connect-wallet-content__progress-icon">
          {props.isSubmitted ? (
            <img src={SumbittedIcon} alt="submited" />
          ) : (
            <img src={DeclinedIcon} alt="declined" />
          )}
        </div>
        <div className="connect-wallet-content__title">
          {props.isSubmitted
            ? 'Transaction submitted!'
            : 'Transaction was declined!'}
        </div>
        <div className="connect-wallet-content__link">
          {props.isSubmitted ? 'View on BscScan' : 'Report'}
        </div>
      </div>
    </div>
  )
}

export const StakingContentInsufficient = () => {
  return (
    <div className="staking-content">
      <div className="staking-content__inner">
        <Title text="Staking" />
        <StakingWarning text="Insufficient $RIA" />
        <div className="staking-content__text">
          You’ll need $RIA to stake in this pool. Buy some $RIA, or make sure
          your NMX isn’t in another pool or LP.
        </div>
      </div>
      <RhombusButton text="Buy $RIA" />
    </div>
  )
}

interface IStakingContent {
  title: string
  className: string
  isLocked: boolean
}
export const StakingContentProgress: FC<IStakingContent> = ({
  title,
  className,
  isLocked
}) => {
  const { stake, balance, minimalLockDuration, loadAllData } =
    useContext(Web3Context)
  const { close, updateNotification, openNotification, updateLoader } =
    useContext(PopupContext)
  const percents = [25, 50, 75, 100]
  const [activePercent, setActivePercent] = useState(0)
  const [count, setCount] = useState('0')
  const [lock, setLock] = useState(minimalLockDuration)
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const handleSuccess = async () => {
    await loadAllData()
    updateNotification(true, `Successfully staked`)
    openNotification()
    close()
    updateLoader(false)
  }
  const handleError = (err: any) => {
    updateNotification(false, err.toString())
    openNotification()
    updateLoader(false)
  }

  const handleStake = async () => {
    const parsedCount = parseEther(count)
    const lockCondition = isLocked ? lock >= minimalLockDuration : true

    if (parsedCount.gt(BigNumber.from(0)) && lockCondition) {
      updateLoader(true)
      try {
        await stake(parsedCount, isLocked ? lock : undefined)
        await handleSuccess()
      } catch (err) {
        handleError(err)
      }
    }
  }

  return (
    <div className={`staking-content ${className}`}>
      <div className="staking-content__inner">
        <Title text={`Stake in ${title} Pool`} />
        <div className="staking-content__balance">
          $RIA Balance: {toBalance(balance)}
        </div>
        <div className="staking-content__body">
          <div className="staking-content__content">
            <div className="staking-content__item">
              <div className="staking-content__head">Stake</div>
              <TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCount(replaceToDigits(e.target.value))
                  setActivePercent(0)
                }}
                value={count}
                isLocked={isLocked}
              />
            </div>
            {isLocked && (
              <div className="staking-content__item">
                <div className="staking-content__head">Lock days</div>
                <div className="staking-content__lock">
                  <input
                    className="staking-content__lock-input"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setLock((prev) => {
                        return +replaceToDigits(e.target.value)
                      })
                    }}
                    value={lock}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="staking-content-buttons">
            {percents.map((amount, index) => (
              <Percent
                className={activePercent === amount ? 'active' : ''}
                action={() => {
                  setActivePercent(amount)
                  setCount(
                    toBalance(BigNumber.from(balance).mul(amount).div(100))
                  )
                }}
                key={index}
                text={amount}
              />
            ))}
          </div>
          {isLocked && (
            <StakingWarning text={`Min Lock – ${minimalLockDuration} days`} />
          )}
        </div>
      </div>
      {isMobile ? (
        <CommonButton onClick={handleStake} text="Confirm" />
      ) : (
        <RhombusButton onClick={handleStake} text="Confirm" />
      )}
    </div>
  )
}

interface IReinvest {
  isLocked: boolean
  earned: BigNumber
  staked: BigNumber
  stakeId?: BigNumber
}

export const Reinvest: FC<IReinvest> = ({
  isLocked,
  earned,
  staked,
  stakeId
}) => {
  const { close, updateNotification, openNotification, updateLoader } =
    useContext(PopupContext)
  const { reinvestAll, reinvest, loadAllData, minimalLockDuration } =
    useContext(Web3Context)
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const handleSuccess = async () => {
    await loadAllData()
    updateNotification(true, 'Successfully reinvested')
    openNotification()
    close()
    updateLoader(false)
  }
  const handleError = (err: any) => {
    console.log(err)
    updateNotification(false, err.reason || err.error.message)
    openNotification()
    updateLoader(false)
  }

  const handleReinvest = async () => {
    if (!earned.isZero()) {
      updateLoader(true)
      try {
        if (stakeId) {
          await reinvest(stakeId, isLocked ? minimalLockDuration : undefined)
        } else {
          await reinvestAll(isLocked)
        }
        await handleSuccess()
      } catch (err) {
        handleError(err)
      }
    }
  }

  return (
    <div className="reinvest-content">
      <div className="reinvest-content__inner">
        <Title text="Reinvest" />
        <div className="reinvest-content__body">
          <div className="reinvest-content-row">
            <div className="reinvest-content-row__head">
              <div className="reinvest-content-row__title">
                $RIA staked now:
              </div>
              <div className="reinvest-content-row__money">~500 USD</div>
            </div>
            <div className="reinvest-content-row__amount">
              {toBalance(staked)}
            </div>
          </div>
          <div className="reinvest-content-row">
            <div className="reinvest-content-row__head">
              <div className="reinvest-content-row__title">
                You will get total staked
              </div>
              <div className="reinvest-content-row__money">~2,000 USD</div>
            </div>
            <div className="reinvest-content-row__amount">
              {toBalance(staked.add(earned))}
            </div>
          </div>
        </div>
      </div>
      {isMobile ? (
        <CommonButton onClick={handleReinvest} text="Reinvest" />
      ) : (
        <RhombusButton onClick={handleReinvest} text="Reinvest" />
      )}
    </div>
  )
}

interface IHarvest {
  reward?: BigNumber
  isLocked: boolean
  stakeId?: BigNumber
}
export const Harvest: FC<IHarvest> = ({ reward, isLocked, stakeId }) => {
  const { close, updateNotification, openNotification, updateLoader } =
    useContext(PopupContext)
  const { harvest, harvestAll, getEarned, loadAllData, minimalLockDuration } =
    useContext(Web3Context)
  const [sum, setSum] = useState(BigNumber.from(0))
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  useEffect(() => {
    setSum(getEarned(isLocked, true))
  }, [getEarned, isLocked])

  const handleSuccess = async () => {
    await loadAllData()
    updateNotification(true, 'Successfully harvest')
    openNotification()
    close()
    updateLoader(false)
  }
  const handleError = (err: any) => {
    console.log(err)
    updateNotification(false, err.reason || err.error.message)
    openNotification()
    updateLoader(false)
  }

  const handleHarvest = async () => {
    if (!reward?.isZero() && !sum.isZero()) {
      updateLoader(true)
      try {
        if (stakeId) {
          await harvest(stakeId, isLocked ? minimalLockDuration : undefined)
        } else {
          await harvestAll(isLocked)
        }
        await handleSuccess()
      } catch (err) {
        handleError(err)
      }
    }
  }

  return (
    <div className="reinvest-content">
      <div className="reinvest-content__inner">
        <Title text="Harvest" />
        <div className="reinvest-content__body">
          <div className="reinvest-content-row">
            <div className="reinvest-content-row__head">
              <div className="reinvest-content-row__title">
                $RIA available for harvest:
              </div>
              <div className="reinvest-content-row__money">~500 USD</div>
            </div>
            <div className="reinvest-content-row__amount">
              {reward ? toBalance(reward) : toBalance(sum)}
            </div>
          </div>
          {isLocked && (
            <Info text='Be careful! After you make the harvest, the pool will be locked again. If you want to withdraw all tokens from the staking, use the "Unstake" button.' />
          )}
        </div>
      </div>
      {isMobile ? (
        <CommonButton onClick={handleHarvest} text="Harvest" />
      ) : (
        <RhombusButton onClick={handleHarvest} text="Harvest" />
      )}
    </div>
  )
}

interface IUnstakeContent {
  locked: boolean
  staked: BigNumber
  stakeId: BigNumber
  earned: BigNumber
}
export const UnstakeContent: FC<IUnstakeContent> = ({
  locked,
  staked,
  stakeId,
  earned
}) => {
  const { withdraw, loadAllData } = useContext(Web3Context)
  const { close, updateNotification, openNotification, updateLoader } =
    useContext(PopupContext)
  const percents = [25, 50, 75, 100]
  const [activePercent, setActivePercent] = useState(locked ? 100 : 0)
  const [count, setCount] = useState('0')
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const handleSuccess = async () => {
    await loadAllData()
    updateNotification(true, 'Successfully unstaked')
    openNotification()
    close()
    updateLoader(false)
  }
  const handleError = (err: any) => {
    console.log(err)
    updateNotification(false, err.reason || err.error.message)
    openNotification()
    updateLoader(false)
  }

  const handleUnstake = async () => {
    let bnCount = parseEther(count)
    if (activePercent === 100 || +count === +toBalance(staked)) {
      bnCount = BigNumber.from(staked).mul(100).div(100)
    }

    if (bnCount.gt(BigNumber.from(0))) {
      updateLoader(true)
      try {
        await withdraw(stakeId, bnCount.add(earned))
        await handleSuccess()
      } catch (err) {
        handleError(err)
      }
    }
  }

  return (
    <div className="unstake-content">
      <div className="unstake-content__inner">
        <Title
          text={
            locked
              ? 'Unstake from Quetzalqoatl pool'
              : 'Unstake from Santa Muerte pool'
          }
        />
        <div className="unstake-content__body">
          {locked ? (
            <div className="reinvest-content-row">
              <div className="reinvest-content-row__head">
                <div className="reinvest-content-row__title">
                  $RIA available for unstake:
                </div>
                <div className="reinvest-content-row__money">~500 USD</div>
              </div>
              <div className="reinvest-content-row__amount">
                {toBalance(staked.add(earned))}
              </div>
            </div>
          ) : (
            <>
              <div className="unstake-content-avliable">
                <div className="unstake-content-avliable__title">
                  Available to Unstake
                </div>
                <div className="unstake-content-avliable__balance">
                  $RIA {toBalance(staked)} + {toBalance(earned)}
                </div>
              </div>
              <TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCount(replaceToDigits(e.target.value))
                  setActivePercent(0)
                }}
                value={count}
              />
              <div className="staking-content-buttons">
                {percents.map((amount, index) => (
                  <Percent
                    className={activePercent === amount ? 'active' : ''}
                    action={() => {
                      setActivePercent(amount)
                      setCount(
                        toBalance(
                          BigNumber.from(
                            amount === 100 ? staked.add(earned) : staked
                          )
                            .mul(amount)
                            .div(100)
                        )
                      )
                    }}
                    key={index}
                    text={amount}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      {isMobile ? (
        <CommonButton onClick={handleUnstake} text="Confirm" />
      ) : (
        <RhombusButton onClick={handleUnstake} text="Confirm" />
      )}
    </div>
  )
}

export const InstallMetamsk = () => {
  return (
    <div className="unstake-content">
      <div className="unstake-content__inner">
        <Title text="Please install metamask" />
      </div>
    </div>
  )
}
