import React, { createContext, useContext, useEffect, useState } from 'react'
import { StakingCards } from './cards/StakingCards'
import { Statistic } from './statistic/Statistic'
import './Staking.scss'
import { StakingTabs } from './tabs/Tabs'
import { Web3Context } from '../../contexts/Web3Context'
import { useAccount } from 'wagmi'
import { PopupContext } from '../../contexts/PopupContext'

interface IStakingConext {
  locked: boolean
  updateLocked: (lock: boolean) => void
}

export const StakingContext = createContext<IStakingConext>(null as any)

export const Staking = () => {
  const [locked, setLocked] = useState(false)
  const { isConnected } = useAccount()
  const updateLocked = (lock: boolean) => setLocked(lock)
  const { loadAllData } = useContext(Web3Context)
  const { updateLoader } = useContext(PopupContext)

  useEffect(() => {
    updateLoader(true)
    loadAllData().then(() => updateLoader(false))
  }, [loadAllData])

  return (
    <StakingContext.Provider value={{ locked, updateLocked }}>
      <div className="staking">
        <div className="staking__inner">
          <StakingCards />
          {isConnected ? <StakingTabs /> : <Statistic />}
        </div>
      </div>
    </StakingContext.Provider>
  )
}
