import React, { FC } from 'react'
import './ActionButton.scss'

interface IActionButton {
  text: string
  isDisabled?: boolean
  action?: () => void
  color?: string
}

export const ActionButton: FC<IActionButton> = ({
  text,
  isDisabled = false,
  action,
  color = 'gold'
}) => {
  return (
    <button
      onClick={action}
      className={`action-button ${color}${
        isDisabled ? ' action-button_disabled' : ''
      }`}
    >
      <div className="action-button__inner">{text}</div>
    </button>
  )
}
