import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useState
} from 'react'
import { BigNumber, ethers } from 'ethers'
import { StakingWrapper } from 'nft-marketplace-typescript/lib/src/contract'
import { secondsToDays, toBalance } from '../utils/Utils'
import { useAccount } from 'wagmi'
import { polygon } from 'wagmi/chains'
import { useEthersSigner } from '../..'

export interface IWeb3Context {
  withdraw: (stakeId: BigNumber, amount: number | BigNumber) => Promise<any>
  stake: (
    amount: number | BigNumber,
    lockDurationInEpochs?: number
  ) => Promise<any>
  minimalLockDuration: number
  getEarned: (isLocked: boolean, withLockOption: boolean) => BigNumber
  getStaked: (isLocked: boolean) => BigNumber
  stakeInfo: any[]
  approve: (amount: number | BigNumber) => Promise<void>
  harvest: (
    stakeId: number | BigNumber,
    lockDurationInEpochs?: number
  ) => Promise<any>
  harvestAll: (isLocked: boolean) => Promise<any>
  reinvestAll: (isLocked: boolean) => Promise<any>
  getStakingReward: (isLocked: boolean) => Promise<BigNumber | undefined>
  reinvest: (
    stakeId: number | BigNumber,
    lockDurationInEpochs?: number
  ) => Promise<any>
  getStakingTokenBalance: (addess: string) => Promise<any>
  balance: BigNumber
  calculateRewardRaw: (
    duration: number | BigNumber,
    amount: number | BigNumber,
    apr: number | BigNumber
  ) => Promise<BigNumber | undefined>
  getHarvestHistory: () => Promise<any[] | undefined>
  getReinvestHistory: () => Promise<
    | {
        stakeId: any
        amount: any
      }[]
    | undefined
  >
  getBlockTimestamp: () => Promise<number | undefined>
  getStakeData: (stakeID: number | BigNumber) => Promise<any>
  loadAllData: () => Promise<void>
  getTotalStaked: () => Promise<BigNumber | undefined>
  unlockApr: number
  lockedApr: number
  totalStaked: string
  init: () => Promise<void>
}

export const Web3Context = createContext<IWeb3Context>(null as any)

export interface IStakeData {
  stakeId: BigNumber
  earned: BigNumber
  data: {
    amount: BigNumber
    apr: BigNumber
    isLocked: boolean
    reward: BigNumber
    stakeEnd: BigNumber
    stakeStart: BigNumber
  }
}

export const Web3Provider = ({ children }: PropsWithChildren) => {
  const [wrapper, setWrapper]: [
    StakingWrapper | undefined,
    Dispatch<SetStateAction<StakingWrapper | undefined>>
  ] = useState()
  const [address, setAddress] = useState('')
  const [minimalLockDuration, setMinimalLockDuration] = useState(0)
  const [stakeInfo, setStakeInfo] = useState<IStakeData[]>([])
  const [balance, setBalance] = useState(BigNumber.from(0))
  const [unlockApr, setUnlockApr] = useState(0)
  const [lockedApr, setLockedApr] = useState(0)
  const [totalStaked, setTotalStaked] = useState('')
  const { isConnected } = useAccount()
  const ethersSigner = useEthersSigner()
  const [timestamp, setTimestamp] = useState(Date.now())
  const ADDRESS = 'address'
  const IS_MAINNET = true
  const IS_TESTNET = false
  const ALCHEMY_TOKEN = 'LkLrbDbXD3DRE67dQdYtpQ-x5YpBvj4q'

  const getVoidSigner = useCallback(() => {
    return new ethers.VoidSigner(
      '',
      new ethers.providers.AlchemyProvider(polygon.network, ALCHEMY_TOKEN)
    )
  }, [])

  const getVoidProvider = useCallback(() => {
    return new ethers.providers.AlchemyProvider(polygon.network, ALCHEMY_TOKEN)
  }, [])

  const init = useCallback(async () => {
    const signer = ethersSigner
    const voidSigner = getVoidSigner()
    const voidProvider = getVoidProvider()
    const voidStakingWrapper = new StakingWrapper(
      IS_TESTNET,
      voidSigner,
      voidProvider
    )
    setWrapper(voidStakingWrapper)
    const timestamp = await voidStakingWrapper.getBlockTimestamp()
    const minimalLockDuration =
      await voidStakingWrapper.getMinimalLockDuration()
    const unlockApr = await voidStakingWrapper.getStakingReward(false)
    const lockedApr = await voidStakingWrapper.getStakingReward(true)
    const totalStaked = await voidStakingWrapper.getTotalStaked(IS_MAINNET)
    if (signer) {
      console.log('connected', isConnected)
      const stakingWrapper = new StakingWrapper(
        IS_TESTNET,
        signer,
        voidProvider
      )
      setWrapper(stakingWrapper)
      const address = await signer.getAddress()
      setAddress(address)
      localStorage.setItem(ADDRESS, address)
      console.log('Ethereum successfully detected!')
    }
    setTimestamp(timestamp)
    setMinimalLockDuration(secondsToDays(minimalLockDuration))
    setUnlockApr(unlockApr.toNumber())
    setLockedApr(lockedApr.toNumber())
    setTotalStaked(toBalance(totalStaked))
  }, [IS_MAINNET, IS_TESTNET, getVoidProvider, getVoidSigner, ethersSigner])

  useEffect(() => {
    init()
  }, [init])

  const loadAllData = useCallback(async () => {
    if (wrapper && address) {
      const [balance, stakeData] = await Promise.all([
        wrapper.getStakingTokenBalance(address),
        wrapper.getAllStakeDataByAddress(address)
      ])
      setBalance(balance)
      setStakeInfo(stakeData)
    }
  }, [address, wrapper])

  function getStaked(isLocked: boolean) {
    return stakeInfo
      .filter((stake) => stake.data.isLocked === isLocked)
      .map((stake) => stake.data.amount)
      .reduce((p, c) => p.add(c), BigNumber.from(0))
  }

  function getEarned(isLocked: boolean, withLockOption: boolean) {
    return stakeInfo
      .filter((stake) => stake.data.isLocked === isLocked)
      .filter((stake) => {
        if (withLockOption && !stake.data.stakeEnd.isZero() && timestamp) {
          return !stake.data.stakeEnd.gt(BigNumber.from(timestamp))
        }
        return true
      })
      .map((stake) => stake.earned)
      .reduce((p, c) => p.add(c), BigNumber.from(0))
  }

  async function getStakeData(stakeID: number | BigNumber) {
    return await wrapper?.getStakeData(stakeID)
  }

  async function approve(amount: number | BigNumber) {
    return await wrapper?.approve(amount)
  }

  async function getStakingReward(isLocked: boolean) {
    return await wrapper?.getStakingReward(isLocked)
  }

  async function stake(
    amount: number | BigNumber,
    lockDurationInEpochs?: number
  ) {
    return await wrapper?.stake(amount, lockDurationInEpochs)
  }

  async function withdraw(stakeId: BigNumber, amount: number | BigNumber) {
    await wrapper?.withdraw(stakeId, amount)
  }

  async function harvest(
    stakeId: number | BigNumber,
    lockDurationInEpochs?: number | undefined
  ) {
    return await wrapper?.harvest(stakeId, lockDurationInEpochs)
  }

  async function harvestAll(isLocked: boolean) {
    return await wrapper?.harvestAll(isLocked)
  }

  async function reinvest(
    stakeId: number | BigNumber,
    lockDurationInEpochs?: number | undefined
  ) {
    return await wrapper?.reinvest(stakeId, lockDurationInEpochs)
  }

  async function reinvestAll(isLocked: boolean) {
    return await wrapper?.reinvestAll(isLocked)
  }

  async function getStakingTokenBalance(address: string) {
    return await wrapper?.getStakingTokenBalance(address)
  }

  async function calculateRewardRaw(
    duration: number | BigNumber,
    amount: number | BigNumber,
    apr: number | BigNumber
  ) {
    return await wrapper?.calculateRewardRaw(duration, amount, apr)
  }

  async function getHarvestHistory() {
    return await wrapper?.getHarvestHistory(address)
  }

  async function getReinvestHistory() {
    return await wrapper?.getReinvestHistory(address)
  }

  async function getBlockTimestamp() {
    return await wrapper?.getBlockTimestamp()
  }

  async function getTotalStaked() {
    return await wrapper?.getTotalStaked(IS_MAINNET)
  }

  return (
    <Web3Context.Provider
      value={{
        withdraw,
        stake,
        minimalLockDuration,
        stakeInfo,
        approve,
        harvest,
        getStakingReward,
        harvestAll,
        reinvest,
        reinvestAll,
        getStakingTokenBalance,
        balance,
        getEarned,
        getStaked,
        calculateRewardRaw,
        getHarvestHistory,
        getReinvestHistory,
        getBlockTimestamp,
        getStakeData,
        loadAllData,
        getTotalStaked,
        unlockApr,
        lockedApr,
        totalStaked,
        init
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}
