import React from 'react'
import './ConnectWalletCardContent.scss'

export const ConnectWalletCardContent = () => {
  return (
    <div className="staking-card-content">
      <div className="staking-card-content__inner">
        <div className="staking-card-content__body">
          <p className="staking-card-content__paragraf">
            If you are looking for short term gains and the ability to fully
            control your assets, Santa Muerte is the deity for you.
          </p>
          <p className="staking-card-content__paragraf">
            Santa Muerte gives you constant blessings for your choice. With a
            regular return percentage, she provides the chance to pull your
            funds out on a daily basis and reinvest in the same order.
          </p>
        </div>
      </div>
    </div>
  )
}
