import React, { FC } from 'react'
import './Border.scss'

type IBorder = {
  className?: string
  classNameInner?: string
  children: React.ReactNode
  onClick?: () => void
}

export const Border: FC<IBorder> = ({
  className,
  classNameInner,
  children,
  onClick
}) => {
  return (
    <div className={`border ${className}`} onClick={onClick}>
      {children}
    </div>
  )
}

export const PopupBorder: FC<IBorder> = ({
  className,
  classNameInner,
  children
}) => {
  return <div className={`popup-border ${className}`}>{children}</div>
}
