import { useContext, useEffect, useState } from 'react'
import { Table } from '../../table/Table'
import { Row } from '../../table/row/Row'
import { RowItem } from '../../table/row/item/RowItem'
import { RowContent } from '../../table/row/content/RowContent'
import { Amount, Date as DateElement } from '../../table/elements/TableElements'
import { ThereIsNoStakes } from '../../Tabs'
import { Web3Context } from '../../../../../contexts/Web3Context'
import { StakingContext } from '../../../Staking'
import { BigNumber } from 'ethers'
import { toBalance } from '../../../../../utils/Utils'
import { PopupContext } from '../../../../../contexts/PopupContext'

interface IHarvest {
  history: any[]
}

type IHarvests = {
  stakeId: BigNumber
  amount: BigNumber
  timestamp: BigNumber
  isLocked: boolean
}

const WithHarvest = ({ history }: IHarvest) => {
  const { locked } = useContext(StakingContext)
  return (
    <Table titleDate="Harvest Date" titleTokenAmount="Harvested $RIA Tokens">
      {history
        .filter((harvest) => harvest.isLocked === locked)
        .sort((a, b) => b.timestamp - a.timestamp)
        .map((harvest, index) => (
          <Rows key={index} harvest={harvest} />
        ))}
    </Table>
  )
}

const Rows = (props: any) => {
  const [count, setCount] = useState(BigNumber.from(0))
  const [date, setDate] = useState(0)

  useEffect(() => {
    setCount(props.harvest.amount)
    setDate(props.harvest.timestamp)
  }, [props.harvest.amount, props.harvest.timestamp])

  return (
    <Row>
      <RowItem>
        <RowContent>
          <DateElement
            date={new Date(date * 1000).toLocaleDateString('en-us', {
              month: 'short',
              day: 'numeric'
            })}
          />
          <Amount number={toBalance(count)} money="0 USD" />
        </RowContent>
      </RowItem>
    </Row>
  )
}

export const Harvests = () => {
  const { getHarvestHistory } = useContext(Web3Context)
  const [harvests, setHarvests] = useState<IHarvests[]>([])
  const { getStakeData } = useContext(Web3Context)
  const { updateLoader } = useContext(PopupContext)
  const { locked } = useContext(StakingContext)

  useEffect(() => {
    const filledHarvest = async () => {
      const harvests = await getHarvestHistory()

      const result: any = []
      if (harvests) {
        for (const harvest of harvests) {
          const stakeData = await getStakeData(harvest.stakeId)
          result.push({
            stakeId: harvest.stakeId,
            amount: harvest.amount,
            timestamp: harvest.timestamp,
            isLocked: stakeData.isLocked
          })
          setHarvests(result)
        }
      }
      if (result.length > 0) {
        setHarvests(result)
      }
    }
    updateLoader(true)
    filledHarvest().then(() => updateLoader(false))
  }, [getHarvestHistory, getStakeData])

  return harvests.filter((harvest) => harvest.isLocked === locked).length >
    0 ? (
    <WithHarvest history={harvests} />
  ) : (
    <ThereIsNoStakes text="You didn't harvest any tokens" />
  )
}
