import { useContext, useRef, useState } from 'react'
import './StakingCards.scss'
import { StakingCard } from './card/StakingCard'
import SantaMuerte from '../../../../assets/cards/santa-muerte.png'
import Quetzalqoatl from '../../../../assets/cards/quetzalqoatl.png'
import { StakingContext } from '../Staking'

export const StakingCards = () => {
  const [cardRole, setCardRole] = useState('unlocked')
  const { updateLocked } = useContext(StakingContext)
  const cards = useRef() as React.MutableRefObject<HTMLInputElement>
  // const isVisible = useIntersection(cards, '0px')

  // useEffect(() => {
  //   if (!isVisible) {
  //     document.body.style.overflow = 'hidden'
  //   } else {
  //     document.body.style.overflow = 'visible'
  //   }
  // }, [isVisible])

  return (
    <div ref={cards} className={'cards'}>
      <div className="cards__inner">
        <StakingCard
          className={cardRole === 'unlocked' ? 'card active' : 'card'}
          role="unlocked"
          title="Santa Muerte"
          imgSrc={SantaMuerte}
          action={() => {
            updateLocked(false)
            setCardRole('unlocked')
          }}
          // visible={isVisible}
        />
        <StakingCard
          className={cardRole === 'locked' ? 'card active' : 'card'}
          role="locked"
          title="Quetzalqoatl"
          imgSrc={Quetzalqoatl}
          isLocked
          action={() => {
            updateLocked(true)
            setCardRole('locked')
          }}
          // visible={isVisible}
        />
      </div>
    </div>
  )
}
