import { useContext, useEffect, useState } from 'react'
import { ActionButton } from '../../../../../../ui/buttons/action/ActionButton'
import './StakingCardContent.scss'
import { Web3Context } from '../../../../../../contexts/Web3Context'
import { PopupContext } from '../../../../../../contexts/PopupContext'
import {
  Harvest,
  Reinvest,
  StakingContentProgress
} from '../../../../../popup/content/PopupContent'
import { BigNumber } from 'ethers'
import { toBalance } from '../../../../../../utils/Utils'
import { StakingCardContext } from '../../StakingCard'

export const StakingCardContent = () => {
  const { getEarned, getStaked } = useContext(Web3Context)
  const { specifyComponent, open } = useContext(PopupContext)
  const { isLocked } = useContext(StakingCardContext)
  const [reward, setReward] = useState(BigNumber.from(0))
  const [optionsReward, setOptionsReward] = useState(BigNumber.from(0))
  const [staked, setStaked] = useState(BigNumber.from(0))

  useEffect(() => {
    setReward(getEarned(isLocked, false))
    setOptionsReward(getEarned(isLocked, true))
    setStaked(getStaked(isLocked))
  }, [getEarned, getStaked, isLocked])

  return (
    <div className="staking-card-content">
      <div className="staking-card-content__inner">
        <div className="staking-card-content-item">
          <div className="staking-card-content-item__head">
            <div className="staking-card-content-item__description">
              <div className="staking-card-content-item__title">
                $RIA Staked
              </div>
              <div className="staking-card-content-item__money">0 USD</div>
            </div>
            <div className="staking-card-content-item__amount">
              {toBalance(staked)}
            </div>
          </div>
          <ActionButton
            action={() => {
              specifyComponent(
                <StakingContentProgress
                  title={isLocked ? 'Quetzalqoatl' : 'Santa Muerte'}
                  className={isLocked ? 'locked' : 'unlocked'}
                  isLocked={isLocked}
                />
              )
              open()
            }}
            text="Stake +"
          />
        </div>
        <div className="staking-card-content-item">
          <div className="staking-card-content-item__head">
            <div className="staking-card-content-item__description">
              <div className="staking-card-content-item__title">
                $RIA Eearned
              </div>
              <div className="staking-card-content-item__money">0 USD</div>
            </div>
            <div className="staking-card-content-item__amount">
              {toBalance(reward)}
            </div>
          </div>
          {!isLocked && (
            <ActionButton
              action={() => {
                if (!optionsReward.isZero()) {
                  specifyComponent(<Harvest isLocked={isLocked} />)
                  open()
                }
              }}
              text="Harvest All"
              color="bronze"
              isDisabled={optionsReward.isZero()}
            />
          )}
        </div>
        <ActionButton
          action={() => {
            if (!staked.isZero() && !optionsReward.isZero()) {
              specifyComponent(
                <Reinvest
                  isLocked={isLocked}
                  staked={staked}
                  earned={optionsReward}
                />
              )
              open()
            }
          }}
          text="Reinvest All"
          isDisabled={staked.isZero() || optionsReward.isZero()}
        />
      </div>
    </div>
  )
}
