import { FC, useContext, useEffect, useState } from 'react'
import { ActionButton } from '../../../../../ui/buttons/action/ActionButton'
import { Amount, Date as DateElement } from '../elements/TableElements'
import './StakePopup.scss'
import { IStakeData, Web3Context } from '../../../../../contexts/Web3Context'
import {
  bigNumberToDate,
  daysToMilliseconds,
  getDaysDiff,
  toBalance,
  toFormattedDate
} from '../../../../../utils/Utils'
import { PopupContext } from '../../../../../contexts/PopupContext'
import {
  Harvest,
  Reinvest,
  UnstakeContent
} from '../../../../popup/content/PopupContent'
import { StakingContext } from '../../../Staking'
import { formatEther } from 'ethers/lib/utils.js'
import { BigNumber } from 'ethers'
import { StakingWarning } from '../../../cards/warning/StakingWarning'

interface IStakePopup {
  action: () => void
  stake: IStakeData
}

export const StakePopup: FC<IStakePopup> = ({ action, stake }) => {
  const { specifyComponent, open } = useContext(PopupContext)
  const { locked } = useContext(StakingContext)
  const { getBlockTimestamp } = useContext(Web3Context)
  const [blockDate, setBlockDate] = useState(Date.now() / 1000)

  useEffect(() => {
    const getTimestamp = async () => {
      const timestamp = await getBlockTimestamp()
      if (timestamp) setBlockDate(timestamp)
    }
    getTimestamp()
  }, [getBlockTimestamp])

  return (
    <div className="table-popup">
      <div className="table-popup__inner">
        <div className="table-popup__title">Staked</div>
        <div className="table-popup__exit" onClick={action}></div>
        <div className="table-popup__body">
          <div className="table-popup-staked">
            <div className="table-popup-staked__head">
              <div className="table-popup-staked__title">
                Staking
                <br />
                Date
              </div>
              <div className="table-popup-staked__title">
                Staked
                <br />
                $RIA tokens
              </div>
            </div>
            <div className="table-popup-staked__body">
              <div className="table-popup-staked__row">
                <DateElement date={toFormattedDate(stake.data.stakeStart)} />
                <Amount number={toBalance(stake.data.amount)} />
              </div>
              <div className="table-popup-staked__buttons">
                {stake.data.stakeEnd.isZero() ||
                blockDate > stake.data.stakeEnd.toNumber() ? (
                  <>
                    <ActionButton
                      action={() => {
                        specifyComponent(
                          <Reinvest
                            earned={stake.earned}
                            isLocked={locked}
                            staked={stake.data.amount}
                            stakeId={stake.stakeId}
                          />
                        )
                        open()
                      }}
                      text="Reinvest"
                      isDisabled={stake.earned.isZero()}
                    />
                    {!locked && (
                      <ActionButton
                        action={() => {
                          specifyComponent(
                            <Harvest
                              reward={stake.earned}
                              isLocked={locked}
                              stakeId={stake.stakeId}
                            />
                          )
                          open()
                        }}
                        color="bronze"
                        text="Harvest"
                        isDisabled={stake.earned.isZero()}
                      />
                    )}
                    <ActionButton
                      action={() => {
                        specifyComponent(
                          <UnstakeContent
                            locked={locked}
                            staked={stake.data.amount}
                            stakeId={stake.stakeId}
                            earned={stake.earned}
                          />
                        )
                        open()
                      }}
                      color="bronze"
                      text="Unstake"
                    />
                  </>
                ) : (
                  <StakingWarning
                    text={`Lock until ${toFormattedDate(stake.data.stakeEnd)}`}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="table-popup__title">Payouts</div>
          <div className="table-popup-payouts">
            <div className="table-popup-payouts__head">
              <div className="table-popup-payouts__title">
                Payout
                <br />
                Date
              </div>
              <div className="table-popup-payouts__title">
                Earned
                <br />
                $RIA tokens
              </div>
              <div className="table-popup-payouts__title">
                Payout
                <br />
                Percentage
              </div>
            </div>
            <div className="table-popup-payouts__body">
              {Array.from(
                {
                  length: stake.data.isLocked
                    ? getDaysDiff(
                        stake.data.stakeEnd.toNumber(),
                        bigNumberToDate(stake.data.stakeStart)
                      )
                    : getDaysDiff(
                        blockDate,
                        bigNumberToDate(stake.data.stakeStart)
                      )
                },
                (_, i) => i + 1
              )
                .reverse()
                .map((num) => (
                  <PopupRow key={num} el={stake} locked={locked} num={num} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PopupRow = (props: any) => {
  const { calculateRewardRaw } = useContext(Web3Context)
  const { updateLoader } = useContext(PopupContext)
  const [count, setCount] = useState(BigNumber.from(0))

  useEffect(() => {
    updateLoader(true)
    calculateRewardRaw(
      props.el.data.amount,
      //hours for now
      daysToMilliseconds(props.num) / 1000,
      props.el.data.apr
    )
      .then((value) => {
        updateLoader(false)
        if (value) setCount(value)
      })
      .catch((err) => console.log(err))
  }, [props.el.data.amount, props.el.data.apr, props.num])

  return (
    <div className="table-popup-payouts__row">
      <DateElement
        date={new Date(
          bigNumberToDate(props.el.data.stakeStart).getTime() +
            daysToMilliseconds(props.num)
        ).toLocaleDateString('en-us', {
          month: 'short',
          day: 'numeric'
        })}
      />
      <Amount number={toBalance(count)} />
      <div className="subrow__percent">
        {count.isZero()
          ? `0%`
          : `${(
              (+formatEther(count) / +formatEther(props.el.data.amount)) *
              100
            ).toFixed(4)}%`}
      </div>
    </div>
  )
}
