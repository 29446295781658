import { useContext } from 'react'
import { CommonButton } from '../../ui/buttons/common/CommonButton'
import { PopupContext } from '../../contexts/PopupContext'
import {
  ConnectWalletContentLoading,
  InstallMetamsk
} from '../../elements/popup/content/PopupContent'
import { useWeb3Modal } from '@web3modal/react'
import { useAccount } from 'wagmi'

export const ConnectWallet = () => {
  const { specifyComponent, open: openModal, close } = useContext(PopupContext)
  const { open } = useWeb3Modal()
  const { isConnected } = useAccount()

  const handleConnectWalletClick = async () => {
    if (!isConnected) {
      open()
      specifyComponent(<ConnectWalletContentLoading />)
      openModal()
      close()
    } else {
      specifyComponent(<InstallMetamsk />)
      openModal()
    }
  }

  return (
    <CommonButton onClick={handleConnectWalletClick} text="Connect Wallet" />
  )
}
