import Error from '../../../assets/icons/declined.svg'
import { RhombusButton } from '../../ui/buttons/rhombus/RhombusButton'
import './NetworkNotSupported.scss'
import { useSwitchNetwork } from 'wagmi'
import { polygon } from 'wagmi/chains'

export const NetworkNotSupported = () => {
  const { switchNetwork, chains } = useSwitchNetwork({ chainId: polygon.id })

  return (
    <div className="network-not-supported">
      <div className="network-not-supported__inner">
        <img src={Error} alt="error" className="network-not-supported__icon" />
        <div className="network-not-supported__title">
          Network is not supported
        </div>
        <div className="network-not-supported__subtitle">
          This page is available on Polygon Network only
        </div>
        <RhombusButton
          onClick={() => {
            if (chains && switchNetwork) {
              switchNetwork(chains[0].id)
            }
          }}
          text="Switch to Polygon"
        />
      </div>
    </div>
  )
}
