import { FC } from 'react'

import styles from './TableElements.module.scss'

interface IDate {
  date: string
}

export const Date: FC<IDate> = ({ date }) => {
  return <div className={styles.table_date}>{date}</div>
}

interface IAmount {
  number: string
  money?: string
}

export const Amount: FC<IAmount> = ({ number }) => {
  return (
    <div className={styles.amount}>
      <div className={styles.amount_number}>{number}</div>
      {/* <div className={styles.amount_money}>{money}</div> */}
    </div>
  )
}
