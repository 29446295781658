import './scss/style.scss'
import Logo from './img/logo.png'
import Staking from './img/staking.png'
import SantaMuerte from './img/cards/santa-muerte.png'
import Lock from './img/icons/lock.svg'
import quetzalqoatl from './img/cards/quetzalqoatl.png'
import ReqLogo1 from './img/requirements/logo1.svg'
import ReqLogo2 from './img/requirements/logo2.svg'
import ReqLogo3 from './img/requirements/logo3.svg'
import ReqLogo4 from './img/requirements/logo5.svg'
import ArrowDown from './img/icons/arrow-down.svg'
import Action1 from './img/action1.png'
import Action2 from './img/action2.png'
import Telegram from './img/social/telegram.svg'
import Discord from './img/social/discord.svg'
import Twitter from './img/social/twitter.svg'
import Medium from './img/social/medium.svg'
import Reddit from './img/social/reddit.svg'
import YouTube from './img/social/youtube.svg'
import { Link, useNavigate } from 'react-router-dom'
import { useContext, useState, FC, useEffect, useRef } from 'react'
import { Web3Context } from '../../contexts/Web3Context'
import { useAccount } from 'wagmi'
import { StatisticItems } from '../staking/statistic/Statistic'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useWeb3Modal } from '@web3modal/react'
import { useMediaQuery } from 'react-responsive'

const faq = [
  {
    title: "How to stake tokens on Calvaria's Sacrificial Altars?",
    content:
      'For staking you need to have MATIC and RIA tokens on the Polygon Mainnet. Log into the dashboard on this website using your web3 wallet, then select your desired pool, amount of RIA to stake and click on the "Stake" button. Next confirm the transaction in the web3 wallet and voila - your RIA has been staked.'
  },
  {
    title: "I've staked my RIA tokens. How can I stake some more?",
    content:
      'This is an option available to both pools, but keep in mind that If you add more RIA tokens to stake using Quetzacoatl’s pool it will be sent as a separate order with a new blocked period. Meanwhile Santa Muerte’s pool has no limits for staking and you can add more RIA to stake with the same parameters. To add more RIA to stake you have to log in to Calvaria\'s Sacrificial Altars dashboard, select the desired pool, amount of RIA to stake and click on the "Stake" button. Then confirm the transaction in your web3 wallet.'
  },
  {
    title: 'Why am I not able to stake?',
    content:
      'Check if you have both your MATIC and RIA tokens on the Main Polygon Network. All staking happens on the Polygon Network only.'
  },
  {
    title: 'I am unable to view my staking order. How do I access it?',
    content:
      'In most cases your authorized session has ended. Please log in with your web3 wallet by clicking the "Connect Wallet" button on this page.'
  },
  {
    title: 'How to restake rewards?',
    content:
      'Go to Calvaria\'s Sacrificial Altars dashboard and look at the relevant pool. If you have available rewards then you will be able to click the "Reinvest" button. Click this button and confirm the transaction in your web3 wallet.'
  },
  {
    title: 'How to withdraw rewards?',
    content:
      'Go to Calvaria\'s Sacrificial Altars dashboard and look at the pools. If you have available rewards then you will be able to click the "Harvest" button. Click on this button and confirm the transaction in your web3 wallet.'
  }
]

const socials = {
  telegram: 'https://t.me/CalvariaChat',
  discord: 'https://discord.com/invite/FTrmXDNu7c',
  twitter: 'https://twitter.com/CalvariaP2E',
  medium: 'https://medium.com/@Calvaria',
  reddit: 'https://www.reddit.com/r/Calvaria/',
  youtube: 'https://www.youtube.com/channel/UCpneCKiJfUHbPdbxS_bu10Q'
}

export const Landing = () => {
  const { unlockApr, lockedApr, minimalLockDuration } = useContext(Web3Context)
  const { isConnected } = useAccount()
  const [isOpen, setIsOpen] = useState(false)
  const { open } = useWeb3Modal()
  const navigate = useNavigate()
  const [isFirstConnect, setIsFirstConnect] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const handleConnect = (e: any) => {
    document.body.classList.remove('lock')
    if (!isConnected) {
      e.preventDefault()
      open()
      setIsFirstConnect(true)
    }
  }

  const handeAnchorsClick = () => {
    setIsOpen(false)
    document.body.classList.remove('lock')
  }

  useEffect(() => {
    if (isConnected && isFirstConnect) {
      setIsFirstConnect(false)
      navigate('/dashboard')
    }
  }, [isConnected])

  return (
    <div className="landing">
      <div className="welcome__bg">
        <header className="header">
          <div className="wrapper">
            <div className="header__container">
              <div className="header__img-top"></div>
              <div className="header__img-bottom"></div>
              <a className="header__logo" href="/">
                <img src={Logo} alt="" />
              </a>
              <nav
                className={`header__nav${isOpen ? ' open' : ''}`}
                id="menuMobile"
              >
                <div
                  className="header__menu-close js-drop-close"
                  data-drop="menuMobile"
                  onClick={() => {
                    setIsOpen(false)
                    document.body.classList.remove('lock')
                  }}
                ></div>
                <ul className="header__menu">
                  <li>
                    <AnchorLink onClick={handeAnchorsClick} href="#statistics">
                      Statistics
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      onClick={handeAnchorsClick}
                      href="#what_is_staking"
                    >
                      What is Staking?
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      onClick={handeAnchorsClick}
                      href="#types_of_calvaria_staking"
                    >
                      Types Of Calvaria Staking
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      onClick={handeAnchorsClick}
                      href="#requirements"
                    >
                      Requirements
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink onClick={handeAnchorsClick} href="#faq">
                      FAQ
                    </AnchorLink>
                  </li>
                  {isTabletOrMobile && (
                    <li>
                      <Link className="header__btn" to="/dashboard">
                        <div
                          className="header__btn-inner"
                          onClick={handleConnect}
                        >
                          {isConnected ? 'Dashboard' : 'Connect Wallet'}
                        </div>
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
              <Link className="header__btn" to="/dashboard">
                <div className="header__btn-inner" onClick={handleConnect}>
                  {isConnected ? 'Dashboard' : 'Connect Wallet'}
                </div>
              </Link>
              <div
                className={`header__hamburger hamburger hamburger--squeeze js-drop-menu${
                  isOpen ? ' is-active' : ''
                }`}
                data-drop="menuMobile"
                onClick={() => {
                  setIsOpen((prev) => !prev)
                  document.body.classList.toggle('lock')
                }}
              >
                <div className="hamburger-box">
                  <div className="hamburger-inner"></div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="welcome" id="statistics">
          <div className="wrapper">
            <h1 className="title">
              Welcome to the Calvaria Sacrificial Altars
            </h1>
            <div className="welcome__text">
              Here you can lock your tokens as a “sacrifice” in one of the 2
              pools known as an “Altar” and one of the deities will greatly
              reward you for your offering. You can choose which pool suits your
              needs the most, in accordance to the goals you are chasing and
              rewards you after.
            </div>
            <StatisticItems />
          </div>
        </section>
      </div>
      <section className="staking" id="what_is_staking">
        <div className="wrapper">
          <h2 className="staking__title">What is staking?</h2>
          <div className="staking__row">
            <div className="staking__text">
              <p>
                Staking is when you lock crypto assets for a set period of time
                to help support the operation of a blockchain. In return for
                staking your crypto, you earn more cryptocurrency.
              </p>
              <p>
                Calvaria staking works in a similar way, yet provides additional
                benefits besides the ability to earn more.
              </p>
              <p>
                If you lock your tokens, you are becoming a part of the project,
                and gain the option to participate in the Calvaria ecosystem
                growth.
              </p>
            </div>
            <div className="staking__img">
              <img src={Staking} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="cards" id="types_of_calvaria_staking">
        <div className="wrapper">
          <h2 className="subtitle tablet-hide">Calvaria Sacrificial Altars</h2>
          <h2 className="subtitle tablet-visible">
            Calvaria <br />
            Sacrificial Altars
          </h2>
          <div className="cards__items">
            <div className="row">
              <div className="cards__col">
                <div className="cards__item">
                  <div className="cards__item-content">
                    <div className="cards__values">
                      <div className="cards__value">
                        <span>APR</span>
                        {unlockApr}%
                      </div>
                      {/* <div className="cards__value">
                        <span>APY</span>18.06%
                      </div> */}
                    </div>
                    <div className="cards__title">Santa Muerte</div>
                    <div className="cards__text">
                      <p>
                        If you are looking for short term gains and the ability
                        to fully control your assets, Santa Muerte is the deity
                        for you.
                      </p>
                      <p>
                        Santa Muerte gives you constant blessings for your
                        choice. With a regular return percentage, she provides
                        the chance to pull your funds out on a daily basis and
                        reinvest in the same order.
                      </p>
                    </div>
                    <Link
                      className="cards__btn btn"
                      to="/dashboard"
                      onClick={handleConnect}
                    >
                      {isConnected ? 'Dashboard' : 'Connect Wallet'}
                    </Link>
                  </div>
                  <div className="cards__img">
                    <img src={SantaMuerte} alt="" />
                  </div>
                </div>
              </div>
              <div className="cards__col">
                <div className="cards__item">
                  <div className="cards__item-content">
                    <div className="cards__values">
                      <div className="cards__value">
                        <span>APR</span>
                        {lockedApr}%
                      </div>
                      {/* <div className="cards__value">
                        <span>APY</span>18.06%
                      </div> */}
                    </div>
                    <div className="cards__lock">
                      <img src={Lock} alt="" />
                      <span className="tablet-hide">min lock </span>
                      {minimalLockDuration} days +
                    </div>
                    <div className="cards__title">Quetzalqoatl</div>
                    <div className="cards__text">
                      <p>
                        Like a true King and the creator of the worlds,
                        Quetzacoatl wants to see loyalty first, and those who
                        are loyal, will be rewarded handsomely. Choose this pool
                        if you are after higher returns and want to see your
                        wealth grow long term.
                      </p>
                      <p>
                        Quetzacoatl requires at least an 8 week lock of your
                        funds, but the rewards will not disappoint you! Another
                        thing to know about this pool is if you want to reinvest
                        in it, a new order will be created.
                      </p>
                    </div>
                    <Link
                      className="cards__btn btn"
                      to="/dashboard"
                      onClick={handleConnect}
                    >
                      {isConnected ? 'Dashboard' : 'Connect Wallet'}
                    </Link>
                  </div>
                  <div className="cards__img">
                    <img src={quetzalqoatl} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="requirements" id="requirements">
        <div className="wrapper">
          <h2 className="requirements__title">Minimum requirements</h2>
          <div className="requirements__text">
            You will need to have a Metamask or KuCoin Wallet or another
            WalletConnect compatible crypto wallet based on web3 technology
            (such as Trust Wallet) in order to be able to stake desired amount
            of RIA. Of course, a Polygon commission fee is also required
            (recommended balance at least 1 MATIC or higher). You should have
            RIA on the Polygon network. You can buy Polygon RIA on{' '}
            <a
              href="https://app.uniswap.org/#/swap?inputCurrency=MATIC&outputCurrency=0xF9cea445c2Ac1668f50CAa2c2924F93606a4a37d"
              target="_blank"
              rel="noreferrer"
            >
              Uniswap
            </a>{' '}
            (don’t forget to switch Uniswap to Polygon). Also you can swap your
            Ethereum RIA to Polygon RIA using our{' '}
            <a
              href="https://bridge.calvaria.io/"
              target="_blank"
              rel="noreferrer"
            >
              bridge
            </a>
            .
          </div>
          <div className="requirements__block">
            <div className="requirements__block-content">
              <div className="requirements__item">
                <div className="requirements__name">Required software</div>
                <div className="requirements__icons">
                  <a
                    className="requirements__icon"
                    href="https://kuwallet.com/"
                  >
                    <img src={ReqLogo1} alt="" />
                  </a>
                  <a className="requirements__icon" href="https://metamask.io/">
                    <img src={ReqLogo2} alt="" />
                  </a>
                  <a
                    className="requirements__icon"
                    href="https://trustwallet.com/"
                  >
                    <img src={ReqLogo3} alt="" />
                  </a>
                  <span className="requirements__icon">
                    <img src={ReqLogo4} alt="" />
                  </span>
                </div>
              </div>
              <div className="requirements__item">
                <div className="requirements__name">Minimum funds balance</div>
                <div className="requirements__value">1 MATIC (POLYGON)</div>
              </div>
              <div className="requirements__item">
                <div className="requirements__name">Minimum staking amount</div>
                <div className="requirements__value">Any amount of RIA</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FAQ />

      <div className="action">
        <img className="action__left-img" src={Action1} alt="" />
        <img className="action__right-img" src={Action2} alt="" />
        <div className="wrapper">
          <div className="action__title">Start Earning by Staking</div>
          <Link
            className="action__btn btn"
            to="/dashboard"
            onClick={handleConnect}
          >
            {isConnected ? 'Dashboard' : 'Connect Wallet'}
          </Link>
        </div>
      </div>

      <div className="social">
        <div className="wrapper">
          <div className="social__title">Calvaria Social Media</div>
          <div className="social__icons">
            <a className="social__icon" href={socials.telegram}>
              <img src={Telegram} alt="" />
            </a>
            <a className="social__icon" href={socials.discord}>
              <img src={Discord} alt="" />
            </a>
            <a className="social__icon" href={socials.twitter}>
              <img src={Twitter} alt="" />
            </a>
            <a className="social__icon" href={socials.medium}>
              <img src={Medium} alt="" />
            </a>
            <a className="social__icon" href={socials.reddit}>
              <img src={Reddit} alt="" />
            </a>
            <a className="social__icon" href={socials.youtube}>
              <img src={YouTube} alt="" />
            </a>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="wrapper">
          <div className="footer__text">
            By using this website and participating in the presale you agree to
            our
            <a
              href="https://static.calvaria.io/misc/calvaria_terms_%26_conditions.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions.
            </a>
            Cryptocurrency may be unregulated in your jurisdiction. The value of
            cryptocurrencies may go down as well as up. Profits may be subject
            to capital gains or other taxes applicable in your jurisdiction.
          </div>
          <div className="footer__copyright">
            Calvaria: Duels of Eternity © 2023
          </div>
        </div>
      </footer>
    </div>
  )
}

const FAQ = () => {
  return (
    <section className="faq" id="faq">
      <div className="wrapper">
        <h2 className="subtitle">FAQ</h2>
        <div className="faq__accordion accordion js-accordion">
          {faq.map((el, index) => (
            <Accordion key={index} title={el.title} content={el.content} />
          ))}
        </div>
      </div>
    </section>
  )
}

interface IAccordion {
  title: string
  content: string
}

const Accordion: FC<IAccordion> = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false)
  const faqItem = useRef<any>(null)

  return (
    <div className="faq__item accordion__item">
      <div
        onClick={() => {
          setIsActive((prev) => !prev)
        }}
        className={`faq__trigger accordion__trigger js-accordion-trigger${
          isActive ? ' active' : ''
        }`}
      >
        {title}
        <img className="faq__arrow" src={ArrowDown} alt="" />
      </div>
      <div
        ref={faqItem}
        className={`faq__content accordion__content js-accordion-content${
          isActive ? ' open' : ''
        }`}
      >
        {content}
      </div>
    </div>
  )
}
