import { useAccount } from 'wagmi'
import { StakingPage } from './StakingPage'
import { Landing } from './components/elements/landing/Landing'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate
} from 'react-router-dom'
import { useEffect } from 'react'

function App() {
  const { isConnected } = useAccount()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const checkConnected = () => {
      if (location.pathname === '/dashboard' && !isConnected) {
        navigate('/')
      }
    }
    checkConnected()
  }, [])

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="dashboard" element={<StakingPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default App
