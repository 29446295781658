import React, { FC } from 'react'
import './RhombusButton.scss'

interface IRhombusButton {
  text: string
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export const RhombusButton: FC<IRhombusButton> = ({ text, onClick }) => {
  return (
    <button onClick={onClick} className="rhombus-button">
      {text}
    </button>
  )
}
