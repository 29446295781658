import React, { createContext, useState } from 'react'
import { Notification } from '../elements/notification/Notification'

export interface IPopup {
  modal: boolean
  open: () => void
  close: () => void
  openNotification: () => void
  closeNotification: () => void
  component: JSX.Element
  specifyComponent: (component: JSX.Element) => void
  isNotification: boolean
  updateNotification: (isSuccess: boolean, text: string) => void
  notification: JSX.Element
  loaderActive: boolean
  updateLoader: (loader: boolean) => void
}

export const PopupContext = createContext<IPopup>(null as any)

export const PopupState = ({ children }: { children: React.ReactNode }) => {
  const [modal, setModal] = useState(false)
  const open = () => {
    setModal(true)
    document.body.style.overflow = 'hidden'
  }
  const close = () => {
    setModal(false)
    document.body.style.overflow = 'visible'
  }
  const [component, setComponent] = useState<JSX.Element>(<Notification />)
  const specifyComponent = (component: JSX.Element) => setComponent(component)
  const [loaderActive, setLoaderActive] = useState(false)

  const updateLoader = (loader: boolean) => {
    setLoaderActive(loader)
  }

  const [isNotification, setIsNotification] = useState(false)
  const openNotification = () => {
    setIsNotification(true)
    setTimeout(() => {
      setIsNotification(false)
    }, 7000)
  }

  const closeNotification = () => setIsNotification(false)
  const [notification, setNotification] = useState<JSX.Element>(
    <Notification />
  )
  const updateNotification = (isSuccess: boolean, text: string) =>
    setNotification(<Notification isSuccess={isSuccess} text={text} />)

  return (
    <PopupContext.Provider
      value={{
        modal,
        open,
        close,
        specifyComponent,
        component,
        openNotification,
        closeNotification,
        notification,
        updateNotification,
        isNotification,
        loaderActive,
        updateLoader
      }}
    >
      {children}
    </PopupContext.Provider>
  )
}
