import React, { useContext, useEffect, useState } from 'react'
import './PercentRate.scss'
import { Web3Context } from '../../contexts/Web3Context'
import { StakingCardContext } from '../staking/cards/card/StakingCard'
import { Watch } from 'react-loader-spinner'

export const PercentRate = (props: any) => {
  const { lockedApr, unlockApr } = useContext(Web3Context)
  const { isLocked } = useContext(StakingCardContext)
  const [apr, setApr] = useState(0)
  const size = '20'
  const Loader = (
    <Watch
      height={size}
      width={size}
      radius="48"
      color="#fff"
      ariaLabel="watch-loading"
      wrapperStyle={{}}
      visible={true}
    />
  )
  useEffect(() => {
    setApr(isLocked ? lockedApr : unlockApr)
  }, [isLocked, lockedApr, unlockApr])

  return (
    <div className="percent-rate">
      <div className="percent-rate__item">
        <div className="percent-rate__title">APR</div>
        <div className="percent-rate__number">{apr === 0 ? Loader : apr}%</div>
      </div>
    </div>
  )
}
